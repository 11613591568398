img {
  max-width: 100%;
}

.marker-cluster-small, .marker-cluster-medium, .marker-cluster-large {
  background-color: #3c90ce4d !important;
}

.marker-cluster-small div, .marker-cluster-medium div, .marker-cluster-large div {
  color: #fff !important;
  background-color: #3c90ce !important;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  text-align: center;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
  font: 12px Helvetica Neue, Arial, Helvetica, sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}

.leaflet-popup {
  z-index: 1000;
}
/*# sourceMappingURL=index.e14dddeb.css.map */
