img {
    max-width: 100%;
}

/* Leaflet cluster marker overrides */
.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
    background-color: rgba(60, 144, 206, 0.3) !important;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
    background-color: rgba(60, 144, 206) !important;
    color: white !important;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font:
        12px "Helvetica Neue",
        Arial,
        Helvetica,
        sans-serif;
}

.marker-cluster span {
    line-height: 30px;
}

/* Fix so map layer does not show on top of popup */
.leaflet-popup {
    z-index: 1000;
}
